import { Button } from "primereact/button";
import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { useEffect, useRef, useState, CSSProperties } from "react";
import { Toast, ToastMessage } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { OtherUserModel } from "../../models/all-user/edit-user-model";
import ClipLoader from "react-spinners/ClipLoader";
import routeCodeServices from "../../services/route-codes/routecode.service";
import CategoryService from "../../services/category/category.service";

interface DataTableProps {
  data: OtherUserModel[];
  handleAddSuccess: () => void;
}

interface ProductDetailModel {
  routeCodeID: number;
  name: string;
  description: string;
}

const CategoryDataTable: React.FC<DataTableProps> = (props) => {
  const menuLeft: any = useRef(null);
  const { t } = useTranslation();
  const toast = useRef<Toast>(null);
  const [allRoutes, setAllRoutes] = useState<any>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [loader, setLoader] = useState<boolean>();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [selectedRouteCode, setSelectedRouteCode] =
    useState<ProductDetailModel | null>(null);

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    position: "absolute", // You can adjust this property if necessary
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const showMessage = (
    label: string,
    message: string,
    severity: ToastMessage["severity"]
  ) => {
    toast.current?.show({
      severity: severity,
      summary: label,
      detail: message,
      life: 3000,
    });
  };

  useEffect(() => {
    setAllRoutes(props.data);
  }, [props.data]);

  const nameEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        type="text"
        value={options.value}
        style={{ width: "95%", border: "1px solid #090" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          options.editorCallback!(e.target.value)
        }
      />
    );
  };

  const onRowEditComplete = async (event: DataTableRowEditCompleteEvent) => {
    let _data = [...allRoutes];
    let { newData, index } = event;
    if (_data[index] !== newData) {
      let putData = {
        categoryID: newData.categoryID,
        name: newData.name,
        description: newData.description,
      };

      try {
        await CategoryService.updateCategory(putData);
        props.handleAddSuccess();
        showMessage("Success", "Category updated successfully", "success");
      } catch (error) {
        showMessage("Error", "Failed to update category", "error");
        console.error("Error updating category:", error);
      }
    }
  };

  const deleteRouteCode = async () => {
    if (!selectedRouteCode) return;

    try {
      await routeCodeServices.deleteRouteCode(selectedRouteCode.routeCodeID);
      props.handleAddSuccess();
      showMessage("Success", "category deleted successfully", "success");
    } catch (error) {
      showMessage("Error", "Failed to delete category", "error");
      console.error("Error deleting category:", error);
    } finally {
      setSelectedRouteCode(null);
      setShowDeleteDialog(false);
    }
  };

  const onHideDeleteDialog = () => {
    setSelectedRouteCode(null);
    setShowDeleteDialog(false);
  };

  const deleteTemplate = (rowData: ProductDetailModel) => {
    return (
      <Button
        icon="pi pi-trash"
        onClick={() => confirmDelete(rowData)}
        style={{
          border: "none",
          color: "gray",
          background: "none",
          borderRight: "120px",
        }}
        className="p-button-rounded"
      />
    );
  };

  const confirmDelete = (rowData: ProductDetailModel) => {
    setSelectedRouteCode(rowData);
    setShowDeleteDialog(true);
  };
  const stateEditor = (options: ColumnEditorOptions) => {
    let editOptionVal =
      typeof options.value === "string"
        ? options.rowData.stateID
        : options.value;
    return <></>;
  };

  return (
    <>
      {loader ? (
        <div className="flex-align-items-center justify-content-center p-4">
          <ClipLoader
            loading={loader}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            cssOverride={override}
            color="#90ee90"
          />
        </div>
      ) : (
        <>
          <Toast ref={toast} position="top-center" />
          <div className="col-12">
            <div className="card border-noround p-2">
              <DataTable
                value={allRoutes?.length > 0 ? allRoutes : allRoutes}
                editMode="row"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                stripedRows
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                onRowEditComplete={onRowEditComplete}
              >
                <Column
                  field="name"
                  header="Name"
                  editor={nameEditor}
                  style={{ width: "32%" }}
                />
                <Column
                  field="tenantName"
                  header="Tenant"
                  editor={stateEditor}
                  style={{ width: "12%" }}
                />
                <Column
                  field="description"
                  header="Description"
                  editor={nameEditor}
                  style={{ width: "32%" }}
                />
                <Column
                  field="isDeleted"
                  header="isDeleted"
                  editor={nameEditor}
                  style={{ width: "32%" }}
                />
                <Column
                  rowEditor
                  header="Action"
                  headerStyle={{ textAlign: "right" }}
                />
              </DataTable>
              <Menu
                popupAlignment={"left"}
                // model={menuItems}
                popup
                ref={menuLeft}
                id="popup_menu_left"
              />
            </div>
          </div>
          <Dialog
            visible={showEditModal}
            className="quickview w-50"
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            modal
            onHide={() => setShowEditModal(false)}
          />
        </>
      )}
    </>
  );
};

export default CategoryDataTable;

import ApiConstant from "../../constants/api.constants";
import api from "../api";

const getCity = async () => {
  try {
    const RouteCodes: any = await api
      .get(ApiConstant.getCity)
      .then((result: any) => {
        if (result) return result.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error reading get country data.", error);
      });
    const response = RouteCodes != null ? RouteCodes : null;
    return response;
  } catch (e) {
    console.log("error in country");
    return [];
  }
};

const saveCity = async (newRouteCode: any) => {
  try {
    const RouteCodes: any = await api
      .post(ApiConstant.getCity, newRouteCode)
      .then((result: any) => {
        if (result) return result.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error reading get country data.", error);
      });
    const response = RouteCodes != null ? RouteCodes : null;
    return response;
  } catch (e) {
    console.log("error in country");
    return [];
  }
};
const updateCity = async (updateRouteCodes: any) => {
  try {
    const RouteCodes: any = await api
      .put(ApiConstant.getCity, updateRouteCodes)
      .then((result: any) => {
        if (result) return result.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error reading country data.", error);
      });
    const response = RouteCodes != null ? RouteCodes : null;
    return response;
  } catch (e) {
    console.log("error in country");
    return [];
  }
};
const deleteRouteCode = async (addressId: number) => {
  try {
    console.warn(ApiConstant.deleteRouteCodeApi + addressId);
    await api
      .delete(ApiConstant.deleteRouteCodeApi + addressId)
      .then((res: any) => {
        if (res) return res.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error deleting Routes data.", error);
      });
  } catch {
    console.log("error in Routes user address");
    return;
  }
};

const cityServices = {
  getCity,
  saveCity,
  updateCity,
  deleteRouteCode,
};

export default cityServices;

import api from "../api";
import ApiConstant from "../../constants/api.constants";

const tenantGuid = process.env.REACT_APP_TENAT_ID;

const getAllCategories = async () => {
  const apiGetHomeCategories = ApiConstant.getHomeCategories + tenantGuid;
  return await api
    .get(apiGetHomeCategories)
    .then(function (response: any) {
      if (response) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch(function (error: any) {
      console.error("error reading get all categories data.", error);
    });
};

const getCategory = async () => {
  try {
    const RouteCodes: any = await api
      .get(ApiConstant.getAllCategory)
      .then((result: any) => {
        if (result) return result.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error reading get country data.", error);
      });
    const response = RouteCodes != null ? RouteCodes : null;
    return response;
  } catch (e) {
    console.log("error in country");
    return [];
  }
};

const saveCategory = async (newRouteCode: any) => {
  try {
    const RouteCodes: any = await api
      .post(ApiConstant.getAllCategory, newRouteCode)
      .then((result: any) => {
        if (result) return result.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error reading get country data.", error);
      });
    const response = RouteCodes != null ? RouteCodes : null;
    return response;
  } catch (e) {
    console.log("error in country");
    return [];
  }
};
const updateCategory = async (updateRouteCodes: any) => {
  try {
    const RouteCodes: any = await api
      .put(ApiConstant.getAllCategory, updateRouteCodes)
      .then((result: any) => {
        if (result) return result.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error reading country data.", error);
      });
    const response = RouteCodes != null ? RouteCodes : null;
    return response;
  } catch (e) {
    console.log("error in country");
    return [];
  }
};
const deleteRouteCode = async (addressId: number) => {
  try {
    console.warn(ApiConstant.deleteRouteCodeApi + addressId);
    await api
      .delete(ApiConstant.deleteRouteCodeApi + addressId)
      .then((res: any) => {
        if (res) return res.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error deleting Routes data.", error);
      });
  } catch {
    console.log("error in Routes user address");
    return;
  }
};

const CategoryService = {
  getCategory,
  saveCategory,
  updateCategory,
  deleteRouteCode,
  getAllCategories,
};

export default CategoryService;

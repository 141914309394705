import { Button } from "primereact/button";
import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown"; // Import Dropdown
import { useEffect, useRef, useState, CSSProperties } from "react";
import { Toast, ToastMessage } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { OtherUserModel } from "../../models/all-user/edit-user-model";
import ClipLoader from "react-spinners/ClipLoader";
import routeCodeServices from "../../services/route-codes/routecode.service";
import stateServices from "../../services/state/state.services";
import countryServices from "../../services/country/country.service";

interface DataTableProps {
  data: OtherUserModel[];
  handleAddSuccess: () => void;
}

interface ProductDetailModel {
  routeCodeID: number;
  name: string;
  description: string;
}

const StateDataTable: React.FC<DataTableProps> = (props) => {
  const menuLeft: any = useRef(null);
  const { t } = useTranslation();
  const toast = useRef<Toast>(null);
  const [allRoutes, setAllRoutes] = useState<any>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [loader, setLoader] = useState<boolean>();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [selectedRouteCode, setSelectedRouteCode] =
    useState<ProductDetailModel | null>(null);
  const [countries, setCountries] = useState<
    { label: string; value: number }[]
  >([]); // State for countries

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const showMessage = (
    label: string,
    message: string,
    severity: ToastMessage["severity"]
  ) => {
    toast.current?.show({
      severity: severity,
      summary: label,
      detail: message,
      life: 3000,
    });
  };

  useEffect(() => {
    setAllRoutes(props.data);

    // Fetch or define countries
    const fetchCountries = async () => {
      try {
        // Example country data, replace with your own API call
        const countryData = await countryServices.getCountry(); // Replace with actual API call
        const formattedCountries = countryData.map((country: any) => ({
          label: country.name,
          value: country.id, // Assuming each country has `id` and `name`
        }));
        setCountries(formattedCountries);
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      }
    };

    fetchCountries();
  }, [props.data]);

  const nameEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        type="text"
        value={options.value}
        style={{ width: "95%", border: "1px solid #090" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          options.editorCallback!(e.target.value)
        }
      />
    );
  };

  const countryEditor = (options: ColumnEditorOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={countries}
        onChange={(e) => options.editorCallback!(e.value)}
        placeholder="Select a Country"
        style={{ width: "100%" }}
      />
    );
  };

  const onRowEditComplete = async (event: DataTableRowEditCompleteEvent) => {
    let _data = [...allRoutes];
    let { newData, index } = event;
    if (_data[index] !== newData) {
      let putData = {
        stateID: newData.stateID,
        name: newData.name,
        countryID: newData.countryID,
      };

      try {
        await stateServices.updateState(putData);
        props.handleAddSuccess();
        showMessage("Success", "State updated successfully", "success");
      } catch (error) {
        showMessage("Error", "Failed to update State", "error");
        console.error("Error updating State:", error);
      }
    }
  };

  const deleteRouteCode = async () => {
    if (!selectedRouteCode) return;

    try {
      await routeCodeServices.deleteRouteCode(selectedRouteCode.routeCodeID);
      props.handleAddSuccess();
      showMessage("Success", "State deleted successfully", "success");
    } catch (error) {
      showMessage("Error", "Failed to delete state", "error");
      console.error("Error deleting state:", error);
    } finally {
      setSelectedRouteCode(null);
      setShowDeleteDialog(false);
    }
  };

  const onHideDeleteDialog = () => {
    setSelectedRouteCode(null);
    setShowDeleteDialog(false);
  };

  const deleteTemplate = (rowData: ProductDetailModel) => {
    return (
      <Button
        icon="pi pi-trash"
        onClick={() => confirmDelete(rowData)}
        style={{
          border: "none",
          color: "gray",
          background: "none",
          borderRight: "120px",
        }}
        className="p-button-rounded"
      />
    );
  };

  const confirmDelete = (rowData: ProductDetailModel) => {
    setSelectedRouteCode(rowData);
    setShowDeleteDialog(true);
  };

  return (
    <>
      {loader ? (
        <div className="flex-align-items-center justify-content-center p-4">
          <ClipLoader
            loading={loader}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            cssOverride={override}
            color="#90ee90"
          />
        </div>
      ) : (
        <>
          <Toast ref={toast} position="top-center" />
          <div className="col-12">
            <div className="card border-noround p-2">
              <DataTable
                value={allRoutes?.length > 0 ? allRoutes : allRoutes}
                editMode="row"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                stripedRows
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                onRowEditComplete={onRowEditComplete}
              >
                <Column
                  field="name"
                  header="Name"
                  editor={nameEditor}
                  style={{ width: "42%" }}
                />
                <Column
                  field="countryID"
                  header="Country"
                  editor={countryEditor} // Use dropdown editor for country
                  style={{ width: "42%" }}
                />
                <Column
                  rowEditor
                  header="Action"
                  headerStyle={{ textAlign: "right" }}
                />
              </DataTable>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StateDataTable;

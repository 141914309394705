import { useState, useEffect } from "react";
import CitySearch from "./citySearch";
import CityDataTable from "./cityDataTable";
import { OtherUserModel } from "../../models/all-user/edit-user-model";
import routeCodeServices from "../../services/route-codes/routecode.service";
import cityServices from "../../services/city/city.services";

const ViewAllCity = () => {
  const [filteredRoutCodes, setFilteredRouteCodes] = useState<OtherUserModel[]>(
    []
  );
  const [routeCodes, setRouteCodes] = useState<OtherUserModel[]>([]);

  function handleSearch(query: string): void {
    if (routeCodes && routeCodes.length > 0) {
      const searchedRouteCodes = routeCodes.filter(
        (routeCode) =>
          routeCode.name &&
          typeof routeCode.name === "string" &&
          routeCode.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredRouteCodes(searchedRouteCodes);
    } else {
      setFilteredRouteCodes([]);
    }
  }

  const reloadPage = (val: boolean) => {};
  const handleAddSuccess = () => {
    getAllRouteCodes();
  };

  const getAllRouteCodes = () => {
    cityServices.getCity().then((response) => {
      setRouteCodes(response);
    });
  };

  useEffect(() => {
    getAllRouteCodes();
  }, []);
  return (
    <>
      <div className="layout-content mt-3 mb-3 grid grid-table-mobile">
        <div className="col-12">
          <div className="flex align-items-center justify-content-between flex-wrap gap-2 w-full card-container mb-0">
            <h5 className="ml-1 mb-0">View City</h5>
            <CitySearch
              onSearch={handleSearch}
              reloadPage={reloadPage}
              handleAddSuccess={handleAddSuccess}
            />
            <CityDataTable
              data={
                filteredRoutCodes.length > 0 ? filteredRoutCodes : routeCodes
              }
              handleAddSuccess={handleAddSuccess}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAllCity;

import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Toast, ToastMessage } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { InputTextarea } from "primereact/inputtextarea";
import routeCodeServices from "../../services/route-codes/routecode.service"; // Import your API service
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import CategoryService from "../../services/category/category.service";

interface RouteCode {
  name: string;
  Description: string;
}

interface SearchPanelProps {
  onSearch: (query: string) => void;
  reloadPage: (value: boolean) => void;
  handleAddSuccess: () => void;
}

const CategorySearch: React.FC<SearchPanelProps> = ({
  onSearch,
  handleAddSuccess,
}) => {
  const { t } = useTranslation();
  const [slectedRouteCode, setSelectedRouteCode] = useState<string>("");
  const [showAddRouteCodeModal, setShowAddRouteCodeModal] = useState(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const toast = useRef<Toast>(null);

  const showMessage = (
    label: string,
    message: string,
    severity: ToastMessage["severity"]
  ) => {
    toast.current?.show({
      severity: severity,
      summary: label,
      detail: message,
      life: 3000,
    });
  };

  const handleSearch = () => {
    onSearch(slectedRouteCode);
  };

  const resetSearchFields = () => {
    setSelectedRouteCode("");
  };

  const handleOnSuccess = async () => {
    const newRouteCode: RouteCode = {
      name: name,
      Description: description,
    };

    try {
      await CategoryService.saveCategory(newRouteCode);
      showMessage("Add category", "category added successfully", "success");
      setShowAddRouteCodeModal(false);
      handleAddSuccess();
      setName("");
      setDescription("");
    } catch (error) {
      showMessage("Error", "Failed to add category", "error");
      console.error("Error saving category:", error);
    }
  };

  return (
    <div
      className="col-12"
      style={{ width: "100%", padding: "0px", marginBottom: "0.5rem" }}
    >
      <div className="card border-noround p-2" style={{ minHeight: "3.5rem" }}>
        <div
          className="input-container font-bold text-white border-round"
          style={{ float: "left", width: "18%" }}
        >
          <div className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={slectedRouteCode}
              onChange={(e) => setSelectedRouteCode(e.target.value)}
              placeholder="Keyword search"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="buttons-container">
          <div className="filter-button font-bold text-white border-round">
            <Button
              className="text-black border-round"
              style={{ width: "100%", textAlign: "center" }}
              onClick={handleSearch}
              severity="success"
            >
              Filter
            </Button>
          </div>
          <div className="filter-button font-bold text-white border-round ml-2">
            <Button
              className="text-black border-round"
              style={{ width: "100%", textAlign: "center" }}
              onClick={resetSearchFields}
              severity="secondary"
              text
              raised
            >
              Reset
            </Button>
          </div>
          <div
            className="add-button-container border-round font-bold ml-3 text-primary"
            style={{ textAlign: "right" }}
          >
            <Button
              className="bg-blue-700 border-round p-button p-button-text p-component text-center text-white"
              style={{ textAlign: "center" }}
              onClick={() => setShowAddRouteCodeModal(true)}
              severity="secondary"
              text
              raised
            >
              Add Category
            </Button>
          </div>
        </div>
        <Dialog
          visible={showAddRouteCodeModal}
          className="quickview w-50"
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          modal
          onHide={() => setShowAddRouteCodeModal(false)}
        >
          <h5 className="border-round mb-3 order-top-color p-2 panel-header-text text-center">
            ADD CATEGORY
          </h5>

          <div className="flex flex-wrap">
            <div className="w-full mb-3 px-3">
              <div className="field">
                <label htmlFor="name" className="label-semi-bold">
                  Name<span className="required">*</span>
                </label>
                <InputText
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  style={{ width: "100%" }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full mb-3 px-3">
              <div className="field">
                <label htmlFor="usage" className="label-semi-bold">
                  Description
                </label>
                <InputTextarea
                  autoResize
                  id="Description"
                  name="Description"
                  placeholder="Description"
                  rows={4}
                  cols={30}
                  style={{ width: "100%" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="text-right mt-4">
            <Button
              className="bg-green-700 text-white p-button p-button-text p-component"
              onClick={handleOnSuccess}
            >
              Save
            </Button>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default CategorySearch;

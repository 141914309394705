import ApiConstant from "../../constants/api.constants";
import api from "../api";

const getState = async () => {
  try {
    const RouteCodes: any = await api
      .get(ApiConstant.getState)
      .then((result: any) => {
        if (result) return result.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error reading get country data.", error);
      });
    const response = RouteCodes != null ? RouteCodes : null;
    return response;
  } catch (e) {
    console.log("error in country");
    return [];
  }
};

const saveState = async (newRouteCode: any) => {
  try {
    const RouteCodes: any = await api
      .post(ApiConstant.getState, newRouteCode)
      .then((result: any) => {
        if (result) return result.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error reading get country data.", error);
      });
    const response = RouteCodes != null ? RouteCodes : null;
    return response;
  } catch (e) {
    console.log("error in country");
    return [];
  }
};
const updateState = async (updateRouteCodes: any) => {
  try {
    const RouteCodes: any = await api
      .put(ApiConstant.getState, updateRouteCodes)
      .then((result: any) => {
        if (result) return result.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error reading country data.", error);
      });
    const response = RouteCodes != null ? RouteCodes : null;
    return response;
  } catch (e) {
    console.log("error in country");
    return [];
  }
};
const deleteRouteCode = async (addressId: number) => {
  try {
    console.warn(ApiConstant.deleteRouteCodeApi + addressId);
    await api
      .delete(ApiConstant.deleteRouteCodeApi + addressId)
      .then((res: any) => {
        if (res) return res.data;
        else return null;
      })
      .catch(function (error: any) {
        console.error("error deleting Routes data.", error);
      });
  } catch {
    console.log("error in Routes user address");
    return;
  }
};

const stateServices = {
  getState,
  saveState,
  updateState,
  deleteRouteCode,
};

export default stateServices;

import { useState, useEffect } from "react";
import CountrySearch from "./countrySearch";
import CountryDataTable from "./countryDataTable";
import { OtherUserModel } from "../../models/all-user/edit-user-model";
import routeCodeServices from "../../services/route-codes/routecode.service";
import countryServices from "../../services/country/country.service";

const ViewAllCountry = () => {
  const [filteredCountry, setFilteredCountry] = useState<OtherUserModel[]>([]);
  const [country, setCountry] = useState<OtherUserModel[]>([]);

  function handleSearch(query: string): void {
    if (country && country.length > 0) {
      const searchedRouteCodes = country.filter(
        (con) =>
          con.name &&
          typeof con.name === "string" &&
          con.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredCountry(searchedRouteCodes);
    } else {
      setFilteredCountry([]);
    }
  }

  const reloadPage = (val: boolean) => {};
  const handleAddSuccess = () => {
    getCountry();
  };

  const getCountry = () => {
    countryServices.getCountry().then((response) => {
      setCountry(response);
    });
  };

  useEffect(() => {
    getCountry();
  }, []);
  return (
    <>
      <div className="layout-content mt-3 mb-3 grid grid-table-mobile">
        <div className="col-12">
          <div className="flex align-items-center justify-content-between flex-wrap gap-2 w-full card-container mb-0">
            <h5 className="ml-1 mb-0">View Country</h5>
            <CountrySearch
              onSearch={handleSearch}
              reloadPage={reloadPage}
              handleAddSuccess={handleAddSuccess}
            />
            <CountryDataTable
              data={filteredCountry.length > 0 ? filteredCountry : country}
              handleAddSuccess={handleAddSuccess}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAllCountry;
